import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ManagerStyles from 'mgr/layout/Styles'
import ModalCover from '../../Generic/Modals/ModalCover'
import { svrExport } from '@sevenrooms/core/utils'

const DialogTypes = Object.freeze({
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
})

const DialogStyles = {
  [DialogTypes.INFO]: {
    color: ManagerStyles.PrimaryBackgroundColor,
    activeColor: ManagerStyles.PrimaryHeaderColor,
    icon: 'i',
  },
  [DialogTypes.WARNING]: {
    color: ManagerStyles.WarningColor,
    activeColor: ManagerStyles.ActiveWarningColor,
    icon: '!',
  },
  [DialogTypes.ERROR]: {
    color: ManagerStyles.ErrorColor,
    activeColor: ManagerStyles.ActiveErrorColor,
    icon: 'X',
  },
}

const MessageBoxStyles = {
  body: {
    backgroundColor: 'white',
    width: 350,
    borderRadius: '7px',
    textAlign: 'center',
    zIndex: 999,
  },
  iconContainer: {
    display: 'inline-block',
    border: '3px solid white',
    borderRadius: '33px',
    width: 60,
    height: 60,
  },
  icon: {
    paddingTop: 20,
    paddingBottom: 20,
    lineHeight: '63px',
    borderRadius: '7px 7px 0px 0',
    backgroundColor: DialogStyles[DialogTypes.INFO].color,
    color: 'white',
    fontSize: '50px',
  },
  contentContainer: {
    marginBottom: 20,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '16px',
    fontWeight: 600,
    color: DialogStyles[DialogTypes.INFO].color,
  },
  explanation: {
    color: '#676869',
    fontSize: '14px',
    lineHeight: '18px',
    paddingLeft: 10,
    paddingRight: 10,
  },
  detailContainer: {
    color: '#9A9B9C',
    marginTop: 15,
  },
  detailLine: {
    marginBottom: 5,
  },
  buttonContainer: {
    padding: 20,
  },
  actionButton: {
    margin: 'auto',
    backgroundColor: DialogStyles[DialogTypes.INFO].color,
    color: 'white',
    borderRadius: '4px',
    paddingTop: 10,
    paddingBottom: 10,
    width: '90%',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: DialogStyles[DialogTypes.INFO].activeColor,
    },
  },
  cancelLink: {
    marginTop: 20,
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    color: DialogStyles[DialogTypes.INFO].color,
    ':hover': {
      color: DialogStyles[DialogTypes.INFO].activeColor,
    },
  },
}

class MessageBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionBtnId: this.props.actionBtnId || 'sr-modal-action-btn',
      cancelBtnId: this.props.cancelBtnId || 'sr-modal-cancel-btn',
    }
  }

  renderAdditionalActions(modifiedActionButtonStyle) {
    const actions = this.props.additionalActions
    if (actions) {
      return actions.map(actionTuple => (
        <div key={actionTuple.text} style={{ ...modifiedActionButtonStyle, marginTop: 10 }} onClick={actionTuple.handleClick}>
          {actionTuple.text}
        </div>
      ))
    }
    return ''
  }

  renderDialog() {
    const { color, activeColor, icon } = this.props.dialogStyles[this.props.dialogType]

    const modifiedIconStyle = { ...MessageBoxStyles.icon, backgroundColor: color }
    const modifiedHeaderStyle = { ...MessageBoxStyles.header, color }
    const modifiedActionButtonStyle = {
      ...MessageBoxStyles.actionButton,
      backgroundColor: color,
      ':hover': { backgroundColor: activeColor },
    }
    const modifiedCancelLinkStyle = { ...MessageBoxStyles.cancelLink, color, ':hover': { color: activeColor } }

    return (
      <div style={{ ...MessageBoxStyles.body, ...this.props.customBodyStyles }}>
        <div style={modifiedIconStyle}>
          <span style={MessageBoxStyles.iconContainer}>{icon}</span>
        </div>
        <div style={MessageBoxStyles.contentContainer}>
          <div style={modifiedHeaderStyle}>{this.props.header}</div>
          <div style={MessageBoxStyles.explanation}>{this.props.explanation}</div>
          {!_.isEmpty(this.props.details) ? (
            <div style={MessageBoxStyles.detailContainer}>
              {this.props.details.map(detail => (
                <div key={detail} style={MessageBoxStyles.detailLine}>
                  {detail}
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
        <div style={MessageBoxStyles.buttonContainer}>
          <div key="_actionButton" id={this.state.actionBtnId} style={modifiedActionButtonStyle} onClick={this.props.handleActionClick}>
            {this.props.actionButtonText}
          </div>
          {this.renderAdditionalActions(modifiedActionButtonStyle)}
          {this.props.isShowCloseButton && (
            <div key="_cancelLink" id={this.state.cancelBtnId} style={modifiedCancelLinkStyle} onClick={this.props.handleCloseClick}>
              {this.props.cancelLinkText}
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    return <ModalCover customModalScreen={this.props.customModalScreen || 'z-index: 999'}>{this.renderDialog()}</ModalCover>
  }
}

MessageBox.DialogType = DialogTypes
MessageBox.defaultProps = {
  dialogStyles: DialogStyles,
  isShowCloseButton: true,
  cancelLinkText: 'Cancel',
}

MessageBox.propTypes = {
  isShowCloseButton: React.PropTypes.bool,
  handleActionClick: React.PropTypes.func.isRequired,
  actionButtonText: React.PropTypes.string.isRequired,
  dialogStyles: React.PropTypes.object,
  cancelLinkText: React.PropTypes.string,
  handleCloseClick: React.PropTypes.func.isRequired,
  dialogType: React.PropTypes.oneOf(Object.values(DialogTypes)),
  header: React.PropTypes.string.isRequired,
  explanation: React.PropTypes.node.isRequired,
  details: React.PropTypes.arrayOf(React.PropTypes.string),
  additionalActions: React.PropTypes.arrayOf(React.PropTypes.object),
  customBodyStyles: React.PropTypes.object,
  customModalScreen: React.PropTypes.object,
}

const wrapped = Radium(MessageBox)
export default wrapped

export const mountConfirmModal = (
  domId,
  header,
  explanation,
  actionButtonText,
  handleCloseClick,
  handleActionClick,
  dialogType,
  additionalActions
) => {
  const container = document.getElementById(domId)
  const unmount = () => ReactDOM.unmountComponentAtNode(container)

  ReactDOM.render(
    <MessageBox
      header={header}
      actionButtonText={actionButtonText}
      handleCloseClick={() => handleCloseClick && handleCloseClick(unmount)}
      handleActionClick={() => handleActionClick && handleActionClick(unmount)}
      dialogType={dialogType}
      explanation={explanation}
      details={['']}
      additionalActions={additionalActions}
    />,
    container
  )
}

svrExport('Components', 'mountConfirmModal', mountConfirmModal)
