import _ from 'lodash'
import { createSelector } from 'reselect'
import { shortDayFormat } from '../../../scripts/utils/constants'

const ALL_LOCATIONS = 'alllocations'

const languageStrings = state => state?.languages?.selectedLanguageStrings

const currentSelectedVenue = state => state?.searchResults?.get('timeSlotVenue') || state.search?.get('selectedVenue')

const selectedVenueEntities = state => state?.venueEntities[currentSelectedVenue(state)]

const currentVenue = state => selectedVenueEntities(state) || state?.venueInfo

const currentVenueWidgetSettings = state => selectedVenueEntities(state) || state?.widgetSettings

// coalescing because we don't want empty string '' to fallback, only null or undefined
export const getLanguageStringValue = (langStringValue, fallbackValue) => langStringValue ?? fallbackValue ?? ''

const selectedVenue = state => state.search.get('selectedVenue')

const selectVenueKey = createSelector([selectedVenue, state => state.venueInfo.urlKey], (selectedVenue, venueInfoUrlKey) =>
  selectedVenue === ALL_LOCATIONS ? venueInfoUrlKey : selectedVenue
)

const selectedVenueName = createSelector(
  [selectVenueKey, state => state.venueInfo.name, state => state.venueEntities],
  (venueKey, defaultVenueName, venueEntities) =>
    venueKey && venueKey in venueEntities ? venueEntities[venueKey].venueName : defaultVenueName
)

const getAvailability = state => state?.availability.toJS()

export const selectLanguageStrings = createSelector(
  [languageStrings, currentVenue, currentVenueWidgetSettings, getAvailability],
  (languageStrings = {}, currentVenue = {}, currentVenueWidgetSettings, getAvailability = {}) => ({
    textCustomPrivacyPolicyLinkLabel: getLanguageStringValue(languageStrings.resWidgetCustomPrivacyPolicyLinkLabel, 'Privacy Policy'),
    textLocationPickerAllLabel: getLanguageStringValue(languageStrings.resWidgetLocationPickerAllLabel, 'All Locations'),
    textLabelCustomCheckoutPolicy: getLanguageStringValue(
      languageStrings.resWidgetCustomPolicyOptInLabel,
      'I agree to the venues required policy'
    ),
    textCustomCheckoutPolicyHeader: getLanguageStringValue(languageStrings.resWidgetCustomPolicyHeader, 'Required checkout policy'),
    specialAttentionMessageHeader: getLanguageStringValue(languageStrings.resWidgetSpecialAttentionLabel, ''),
    specialAttentionMessageBody: getLanguageStringValue(languageStrings.resWidgetSpecialAttentionInfoBody, ''),
    textCustomGdprDietaryTagOptinLabel: getLanguageStringValue(
      languageStrings.resWidgetGdprOptInLabel,
      'You agree to allow us to retain this sensitive personal information (ie food allergies, medical conditions, etc.) in order to provide you with a more tailored service.'
    ),
    textCustomGdprDietaryTagOptinErrMsg: getLanguageStringValue(
      languageStrings.resWidgetGdprOptInErrorMsg,
      'Please either confirm consent or remove Dietary Restrictions tags to book.'
    ),
    textCustomGdprPolicyLinkLabel: getLanguageStringValue(languageStrings.resWidgetCustomGdprPolicyLinkLabel, 'GDPR Policy'),
    textTermsOfServiceLinkLabel: getLanguageStringValue(languageStrings.resWidgetTermsOfServiceLinkLabel, 'Terms of Service'),
    textPolicyDisclaimer: getLanguageStringValue(languageStrings.resWidgetPolicyDisclaimerText, 'By clicking "submit" you agree to'),
    textDate: getLanguageStringValue(languageStrings.resWidgetDatePickerLabel, 'Date'),
    textGuests: getLanguageStringValue(languageStrings.resWidgetGuestsLabel, 'Guests'),
    textGuest: getLanguageStringValue(languageStrings.resWidgetGuestLabel, 'Guest'),
    textTime: getLanguageStringValue(languageStrings.resWidgetTimePickerLabel, 'Time'),
    textDuration: getLanguageStringValue(languageStrings.resWidgetDurationPickerLabel, 'Duration'),
    textSelectTimeDropdown: getLanguageStringValue(languageStrings.resWidgetSelTimePickerDdLabel, 'Select desired time'),
    textSelectDurationDropdown: getLanguageStringValue(languageStrings.resWidgetSelDurationDdLabel, 'Select desired duration'),
    textSelectPartySizeDropdown: getLanguageStringValue(languageStrings.resWidgetSelPartySizeDdLabel, 'Select desired party size'),
    textSubmitButtonLabel: getLanguageStringValue(languageStrings.resWidgetCheckoutSubmitButtonLabel, 'Submit'),
    textSaveButtonLabel: getLanguageStringValue(languageStrings.resWidgetSaveButtonLabel, 'Save'),
    textCancelButtonLabel: getLanguageStringValue(languageStrings.resWidgetCancelButtonLabel, 'Cancel'),
    textSelectButtonLabel: getLanguageStringValue(languageStrings.resWidgetSelectButtonLabel, 'Select'),
    textPromoButtonApplyLabel: getLanguageStringValue(languageStrings.resWidgetPromoButtonApplyLabel, 'Apply'),
    textPromoButtonRemoveLabel: getLanguageStringValue(languageStrings.resWidgetPromoButtonRemoveLabel, 'Remove'),
    textAddButtonLabel: getLanguageStringValue(languageStrings.resWidgetCheckoutAddLabel, 'Add'),
    textHoldingTable: getLanguageStringValue(languageStrings.resWidgetHoldingTableLabel, "We're holding this table for"),
    textHoldExpired: getLanguageStringValue(
      languageStrings.resWidgetHoldExpiredLabel,
      'Your table is no longer being held, you can still try to book it.'
    ),
    textYourInformation: getLanguageStringValue(languageStrings.resWidgetGuestInformationHeader, 'Your Information'),
    textSalutation: getLanguageStringValue(languageStrings.resWidgetSalutation, 'Title'),
    textFirstName: getLanguageStringValue(languageStrings.resWidgetFirstNameLabel, 'First Name'),
    textLastName: getLanguageStringValue(languageStrings.resWidgetLastNameLabel, 'Last Name'),
    textEmailAddress: getLanguageStringValue(languageStrings.resWidgetEmailLabel, 'Email Address'),
    textPhoneNumber: getLanguageStringValue(languageStrings.resWidgetPhoneNumberLabel, 'Phone Number'),
    textBirthday: getLanguageStringValue(languageStrings.resWidgetBirthdayLabel, 'Birthday'),
    textPostalCode: getLanguageStringValue(languageStrings.resWidgetPostalCodeLabel, 'Postal Code'),
    textPayment: getLanguageStringValue(languageStrings.resWidgetPaymentLabel, 'Payment'),
    textCardRequired: getLanguageStringValue(languageStrings.resWidgetCcRequiredLabel, 'Credit Card Required'),
    textCreditCardNumber: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetCcNumberLabel,
      'Credit Card Number'
    ),
    textExpMonth: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetCcExpMonthLabel,
      'mm'
    ),
    textExpYear: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetCcExpYearLabel,
      'yyyy'
    ),
    textCvv: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetCcCvvLabel,
      'cvv'
    ),
    textZipCode: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetCcZipcodeLabel,
      'zipcode'
    ),
    textCreditCardExpYearDefaultLabel: getLanguageStringValue(languageStrings.resCreditCardExpYearDefaultLabel, 'yy'),
    textSummary: getLanguageStringValue(languageStrings.resWidgetSummaryLabel, 'Summary'),
    textReservationNotes: getLanguageStringValue(languageStrings.resWidgetReservationNotesHeader, 'Reservation Notes'),
    textUpgrades: getLanguageStringValue(languageStrings.resWidgetUpgradesLabel, 'Upgrades'),
    textServiceCharge: getLanguageStringValue(languageStrings.resWidgetServiceChargeLabel, 'service charge'),
    textSubtotal: getLanguageStringValue(languageStrings.resWidgetReservationLabel, 'Reservation'),
    textTax: getLanguageStringValue(languageStrings.resWidgetTaxLabel, 'Tax'),
    textTip: getLanguageStringValue(languageStrings.resWidgetTipLabel, 'Tip'),
    textSelectATip: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetSelectATipLabel,
      'select a tip'
    ),
    textTotal: getLanguageStringValue(languageStrings.resWidgetOrderTotalLabel, 'Order Total'),
    textVenueGroupMarketingOptIn: getLanguageStringValue(
      languageStrings.resWidgetCheckoutVenueGroupOptIn,
      'Receive news and offers for all our locations.'
    ),
    textReservationSmsOptIn: getLanguageStringValue(
      languageStrings.policyUsTwilioSmsOptIn,
      'I agree to receive automated text messages, such as reservation reminders, at the phone number provided.'
    ),
    textAgreeToPolicy: getLanguageStringValue(languageStrings.resWidgetCancelPolicyOptInLabel, 'I agree to the Cancellation Policy'),
    textDietaryRestrictions: getLanguageStringValue(languageStrings.resWidgetDietaryRestrictionsLabel, 'Dietary Restrictions'),
    textSpecialOccasion: getLanguageStringValue(languageStrings.resWidgetSpecialOccasionLabel, 'Special Occasion'),
    textAgeConsent: getLanguageStringValue(languageStrings.resWidgetAgeConsentLabel, 'I certify I am above the age of '),
    textSuccessHeader: getLanguageStringValue(languageStrings.resWidgetConfirmSuccessHeader, 'Thank you.'),
    textSuccessBody: getLanguageStringValue(languageStrings.resWidgetConfirmSuccessBody, 'We look forward to welcoming you.'),
    textRequestReceived: getLanguageStringValue(languageStrings.resWidgetRequestReceivedLabel, 'We have received your request.'),
    textConfirmationSentTo: getLanguageStringValue(
      languageStrings.resWidgetConfirmEmailSent,
      'Your confirmation and details have been sent to <email>'
    ),
    textYouWillBeContacted: getLanguageStringValue(
      languageStrings.resWidgetConfirmVenueToContactGuestLabel,
      'Someone will be in touch with you shortly at <email>'
    ),
    textModifySuccessHeader: getLanguageStringValue(languageStrings.resWidgetUpdateHeader, 'Thank you.'),
    textModifySuccessBody: getLanguageStringValue(languageStrings.resWidgetUpdateBody, 'You’ve successfully updated your reservation.'),
    textModifySuccessConfirmation: getLanguageStringValue(
      languageStrings.resWidgetUpdateEmailSent,
      'Your updated booking confirmation has been sent to <email>'
    ),
    textUpgrade: getLanguageStringValue(languageStrings.resWidgetUpgradePageHeader, 'Upgrade your reservation'),
    textSearch: getLanguageStringValue(languageStrings.resWidgetSearchButton, 'Search'),
    textCantFind: getLanguageStringValue(languageStrings.resWidgetSubmitRequestHeader, "Can't find what you're looking for?"),
    textSubmitRequest: getLanguageStringValue(languageStrings.resWidgetSubmitRequestButton, 'Submit a request'),
    textCreateAccount: getLanguageStringValue(
      languageStrings.resWidgetLoginPageHeader,
      'Please select an option below to continue checkout:'
    ),
    textLoginWithFacebook: getLanguageStringValue(languageStrings.resWidgetFacebookCheckoutButton, 'Login with Facebook'),
    textLoginWithGoogle: getLanguageStringValue(languageStrings.resWidgetGoogleCheckoutButton, 'Login with Google'),
    textContinueAsGuest: getLanguageStringValue(languageStrings.resWidgetGuestCheckoutButton, 'Continue as a Guest'),
    textMarketingPolicy: getLanguageStringValue(
      languageStrings.resWidgetMarketingPolicyHeader || languageStrings.waitlistWidgetMarketingPolicyText,
      'Marketing Policy'
    ),
    textReservationSmsPolicy: getLanguageStringValue(languageStrings.resWidgetReservationSmsPolicyHeader, 'Reservation Sms Policy'),

    textCancellationPolicy: getLanguageStringValue(languageStrings.resWidgetCancellationPolicyHeader, 'Cancellation Policy'),
    textAdditionalSelection: getLanguageStringValue(
      // this string doesn't exist
      languageStrings.resWidgetAdditionalSelectionHeader,
      'Dining Additional Selection'
    ),
    textPerPerson: getLanguageStringValue(languageStrings.resWidgetPerPersonLabel, 'person'),
    textPerReservation: getLanguageStringValue(languageStrings.resWidgetPerReservationLabel, 'reservation'),
    textRequestDetails: getLanguageStringValue(languageStrings.resWidgetRequestDetailsLabel, 'Request Details'),
    textStartTime: getLanguageStringValue(languageStrings.resWidgetStartTimeLabel, 'Start time'),
    textPoweredBy: languageStrings.resWidgetPoweredByLabel,
    textOtherDates: getLanguageStringValue(languageStrings.resWidgetOtherDatesLabel, 'Other dates with availability at <venue>'),
    textNoAdditional: getLanguageStringValue(
      languageStrings.resWidgetNoAdditionalAvail,
      'There is no additional availability at this time.'
    ),
    textSelectATime: getLanguageStringValue(languageStrings.resWidgetSearchResultsHeader, 'Select a time at <venue>'),
    textSelectATimeAllLocations: getLanguageStringValue(languageStrings.resWidgetSearchResultsHeaderAllLocations, 'Select a time'),
    textCrossSellVenueShortDescription: getLanguageStringValue(languageStrings.resWidgetCrossSellVenueShortDescription, ''),
    textCrossSellVenueLongDescription: getLanguageStringValue(languageStrings.resWidgetCrossSellVenueLongDescription, ''),
    textNoAvailabilityForAccessRules: getLanguageStringValue(
      languageStrings.resWidgetNoAvailability,
      'Unfortunately there is no availability at the selected time.'
    ),
    textRestaurantClosed: getLanguageStringValue(
      languageStrings.resWidgetNoShiftsOnDate,
      'We are closed on <date>. Book one of these upcoming dates.'
    ),
    textBlackoutOn: getLanguageStringValue(
      languageStrings.resWidgetBlackoutDate,
      'There is no online availability for this day. Please call the restaurant.'
    ),
    textOtherLocations: getLanguageStringValue(languageStrings.resWidgetOtherLocationsLabel, 'Availability at our other locations'),
    textNoAdditionalOtherVenues: getLanguageStringValue(
      languageStrings.resWidgetOtherLocationsNoAvail,
      'There is no additional availability at our other venues.'
    ),
    textLoginOrLabel: getLanguageStringValue(languageStrings.resWidgetLoginOrLabel, 'OR'),
    textPromoLabel: getLanguageStringValue(languageStrings.resWidgetPromoLabel, 'Promo Code'),
    textReservationNotesPlaceholder: getLanguageStringValue(languageStrings.resWidgetReservationNotesPlaceholder, 'Leave a note...'),
    textTagLabelYours: getLanguageStringValue(languageStrings.resWidgetTagLabelYours, 'Yours'),
    textTagLabelYourGuests: getLanguageStringValue(languageStrings.resWidgetTagLabelYourGuests, 'Your Guests'),
    textWidgetTitle: getLanguageStringValue(languageStrings.resWidgetTitle, ''),
    textHourLabel: getLanguageStringValue(languageStrings.resWidgetHourLabel, 'hr'),
    textMinuteLabel: getLanguageStringValue(languageStrings.resWidgetMinuteLabel, 'min'),
    textSpinnerRequestProcessingLabel: getLanguageStringValue(languageStrings.resWidgetRequestProcessingLabel, 'Processing Request'),
    textSpinnerFindingLabel: getLanguageStringValue(languageStrings.resWidgetLoadingLabel, 'Finding Reservations'),
    textWidgetPreferredLanguageLabel: getLanguageStringValue(
      languageStrings.resWidgetPreferredLanguageLabel,
      'Preferred communication language'
    ),
    textVenueSpecificMarketingOptIn: getLanguageStringValue(
      languageStrings.resWidgetCheckoutVenueOptIn,
      'Receive news and offers via Email for <venue>'
    ),
    textVenueSmsMarketingOptIn: getLanguageStringValue(
      languageStrings.resWidgetCheckoutVenueSmsOptIn,
      'Receive news and offers via text for <venue>'
    ),
    textWidgetModifyLabel: getLanguageStringValue(languageStrings.resWidgetModifyLabel, 'Modify'),
    textUpgradeRequiredLabel: getLanguageStringValue(languageStrings.resWidgetUpgradePageRequiredLabel, 'REQUIRED'),
    textUpgradeMustAtLeastLabel: getLanguageStringValue(languageStrings.resWidgetUpgradePageMustLabel, 'Must select at least'),
    textUpsellsSelectedLabel: getLanguageStringValue(languageStrings.resWidgetUpgradePageSelectLabel, 'selected'),
    textUpgradeNextLabel: getLanguageStringValue(languageStrings.resWidgetUpgradePageNextButton, 'next'),
    textUpgradeEachLabel: getLanguageStringValue(languageStrings.resWidgetUpgradePageEachLabel, 'each'),
    textCustomCheckoutPolicy: getLanguageStringValue(languageStrings.policyCustomCheckout, getAvailability.customCheckoutPolicy),
    textAnd: getLanguageStringValue(languageStrings.commonAndText, 'and'),
    waitlistTextAgreeToWaitlistPolicyModal: getLanguageStringValue(
      languageStrings.policyAgreeToWaitlist,
      "Tables will be held up to 5 minutes from the time that we've notified you that your table is ready."
    ),
    waitlistTextEstimatedWaitTime: getLanguageStringValue(languageStrings.waitlistWidgetEstimatedWaitTimeLabel, 'ESTIMATED WAIT TIME'),
    waitlistTextButton: getLanguageStringValue(languageStrings.waitlistWidgetJoinWaitlistButton, 'Join Waitlist'),
    waitlistTextAgreeToWaitlistPolicy: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistPolicyText,
      'I agree to the waitlist policy *'
    ),
    waitlistTextSelectPartySize: getLanguageStringValue(languageStrings.waitlistWidgetSelectPartySizeLabel, 'SELECT PARTY SIZE'),
    waitlistTextAdditionalNotes: getLanguageStringValue(languageStrings.waitlistWidgetAdditionalNotesLabel, 'Additional Notes'),
    waitlistTextWaitlistTermsText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistTermsText,
      "By clicking Join Waitlist you agree to SevenRooms' Terms of Service, Privacy Policy and GDPR Policy"
    ),
    waitlistTermsOfServiceText: getLanguageStringValue(languageStrings.waitlistWidgetTermsOfServiceLabel, 'Terms of Service'),
    waitlistPrivacyPolicyText: getLanguageStringValue(languageStrings.waitlistWidgetPrivacyPolicyLabel, 'Privacy Policy'),
    waitlistGdprPolicyText: getLanguageStringValue(languageStrings.waitlistWidgetGdprPolicyLabel, 'GDPR Policy'),
    waitlistAgreePolicyModalTitle: getLanguageStringValue(languageStrings.waitlistWidgetWaitlistPolicyLabel, 'Waitlist Policy'),
    waitlistTextLoadingWaitlist: getLanguageStringValue(languageStrings.waitlistWidgetLoadingWaitlistLabel, 'Loading Waitlist'),
    waitlistFirstNameLabel: getLanguageStringValue(languageStrings.waitlistWidgetFirstNameLabel, 'First Name'),
    waitlistLastNameLabel: getLanguageStringValue(languageStrings.waitlistWidgetLastNameLabel, 'Last Name'),
    waitlistEmailAddressLabel: getLanguageStringValue(languageStrings.waitlistWidgetEmailLabel, 'Email Address'),
    waitlistTextPhoneNumberLabel: getLanguageStringValue(languageStrings.waitlistWidgetPhoneNumberLabel, 'Phone Number'),
    waitlistTextReservationNotes: getLanguageStringValue(languageStrings.waitlistWidgetAdditionalNotesLabel, 'Additional Notes'),
    waitlistTextVenueGroupMarketingOptIn: getLanguageStringValue(
      languageStrings.waitlistWidgetGroupMarketingOptInLabel,
      'Receive news and offers for all our locations'
    ),
    waitlistTextLeaveWaitlist: getLanguageStringValue(languageStrings.waitlistWidgetLeaveWaitlistLabel, 'Leave Waitlist'),
    waitlistTextMakeReservation: getLanguageStringValue(languageStrings.waitlistWidgetMakeReservationLabel, 'Make a reservation'),
    waitlistTextViewMap: getLanguageStringValue(languageStrings.waitlistWidgetViewMapLabel, 'View map'),
    waitlistTextViewMenu: getLanguageStringValue(languageStrings.waitlistWidgetViewMenuLabel, 'View menu'),
    waitlistTextVenueClosed: getLanguageStringValue(languageStrings.waitlistWidgetVenueClosedLabel, 'Sorry we are closed'),
    waitlistTextWaitlistUnavailable: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistUnavailableLabel,
      'Waitlist is currently unavailable'
    ),
    waitlistTextHideQuoteTimeArrivalMessage: getLanguageStringValue(
      languageStrings.waitlistWidgetArriveTextMessageLabel,
      "We'll send you a text when its time to arrive"
    ),
    waitlistTextArriveBy: getLanguageStringValue(languageStrings.waitlistWidgetArriveByLabel, 'Please arrive by'),
    waitlistTextArriveByMinutes: getLanguageStringValue(languageStrings.waitlistWidgetArriveInMinutesLabel, 'Please arrive in'),
    waitlistTextForYourPartyofLabel: getLanguageStringValue(languageStrings.waitlistWidgetForPartySizeLabel, 'for your party of'),
    waitlistForLabel: getLanguageStringValue(languageStrings.waitlistWidgetForLabel, 'for'),
    waitlistMakeReservationLabel: getLanguageStringValue(languageStrings.waitlistWidgetMakeReservationLabel, 'Make a reservation'),
    waitlistNoAvailabilityLabel: getLanguageStringValue(
      languageStrings.waitlistWidgetNoAvailabilityLabel,
      'There is currently no availability for your selected party size. Please try again later or book a reservation for a later date.'
    ),
    waitlistWidgetAgreeToTermsLabel: getLanguageStringValue(
      languageStrings.waitlistWidgetAgreeToTermsLabel,
      'By clicking Join Waitlist you agree to'
    ),
    waitlistWidgetAndText: getLanguageStringValue(languageStrings.waitlistWidgetAndText, 'and'),
    waitlistWidgetContactUsLabel: getLanguageStringValue(languageStrings.waitlistWidgetContactUsLabel, 'Contact us'),
    waitlistWidgetVenueClosedText: getLanguageStringValue(languageStrings.waitlistWidgetVenueClosedText, 'Check back soon'),
    waitlistWidgetWaitlistTableReadyText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistTableReadyText,
      'Your table is ready, please check in with the host to be seated'
    ),
    waitlistWidgetWaitlistNoShowText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistNoShowText,
      'You are no longer on the waitlist, please see host or re-join to proceed'
    ),
    waitlistWidgetWaitlistAwaitingConfText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistAwaitingConfText,
      'Your table is almost ready, please check in with the host to be seated'
    ),
    waitlistWidgetWaitlistRemovedText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistRemovedText,
      'You have been removed from the waitlist'
    ),
    waitlistWidgetWaitlistCanceledText: getLanguageStringValue(
      languageStrings.waitlistWidgetWaitlistCanceledText,
      'You have been removed from the waitlist'
    ),
    waitlistWidgetDefaultStatusText: getLanguageStringValue(
      languageStrings.waitlistWidgetDefaultStatusText,
      'Please hold on, your status is being updated.'
    ),
    waitlistWidgetAddingToWaitlistText: getLanguageStringValue(languageStrings.waitlistWidgetAddingToWaitlistText, 'Adding to Waitlist'),
    waitlistWidgetRemovingFromWatilistText: getLanguageStringValue(
      languageStrings.waitlistWidgetRemovingFromWatilistText,
      'Removing from Waitlist'
    ),
    waitlistWidgetBlankErrorText: getLanguageStringValue(languageStrings.waitlistWidgetBlankErrorText, "Can't Be Blank"),
    waitlistWidgetMinsText: getLanguageStringValue(languageStrings.waitlistWidgetMinsText, 'mins'),
    waitlistWidgetInvalidErrorText: getLanguageStringValue(languageStrings.waitlistWidgetInvalidErrorText, 'Is Not Valid'),
    waitlistWidgetQuoteTimeHeaderLabel: getLanguageStringValue(
      languageStrings.waitlistWidgetQuoteTimeHeaderLabel,
      "Join the Waitlist and we'll send you a text when your table is ready!"
    ),
    waitlistWidgetPrivacyPolicyLabel: getLanguageStringValue(languageStrings.waitlistWidgetPrivacyPolicyLabel, 'Privacy Policy'),
    waitlistWidgetSpecialAttentionLabel: getLanguageStringValue(
      languageStrings.waitlistWidgetSpecialAttentionLabel,
      currentVenue.specialAttentionMessageHeader
    ),
    waitlistWidgetSpecialAttentionInfoBody: getLanguageStringValue(
      languageStrings.waitlistWidgetSpecialAttentionInfoBody,
      currentVenue.specialAttentionMessageBody
    ),
    policyVenueGroupMarketing: getLanguageStringValue(
      languageStrings.policyVenueGroupMarketing,
      "From time to time, we'll contact you via email to keep you in the loop about what's happening at all existing and new locations. By clicking this, you will be resubscribed to any of our locations from which you previously unsubscribed."
    ),
    policyVenueSpecificMarketing: getLanguageStringValue(
      languageStrings.policyVenueSpecificMarketing,
      "From time to time, we'll contact you via email to keep you in the loop about what's happening at our venue!"
    ),
    policyVenueSpecificSmsMarketing: getLanguageStringValue(
      languageStrings.policyVenueSpecificSmsMarketing,
      "From time to time, we'll contact you via text to keep you in the loop about what's happening at our venue."
    ),
    policySmsOptIn: getLanguageStringValue(
      languageStrings.policySmsOptIn,
      'By opting in, you agree to receive SMS updates and reminders about your reservations.'
    ),
    policyUsTwilioSmsOptInSubfooter: getLanguageStringValue(
      languageStrings.policyUsTwilioSmsOptInSubfooter,
      '<i>For SMS, reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Message & data rates may apply.</i>'
    ),
    resWidgetPriorityAlertsAlreadyExistsError: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsAlreadyExistsError,
      'An alert has already been set up during this time period. If you wish to set up another alert, please modify the date or the party size.'
    ),
    resWidgetPriorityAlertsDetailsHeader: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsDetailsHeader,
      'Priority Alert Details'
    ),
    resWidgetPriorityAlertsHeader: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsHeader,
      'Alert me on this date when a time slot has opened:'
    ),
    resWidgetPriorityAlertsReservationTimeBetween: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsReservationTimeBetween,
      'Reservation Time Between:'
    ),
    resWidgetPriorityAlertsSetAlertButton: getLanguageStringValue(languageStrings.resWidgetPriorityAlertsSetAlertButton, 'Alert Me'),
    resWidgetPriorityAlertsYourDetails: getLanguageStringValue(languageStrings.resWidgetPriorityAlertsYourDetails, 'Your Details'),
    textPriorityAlertsSuccessHeader: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsSuccessHeader,
      'Your Priority Alert Has Been Set'
    ),
    textPriorityAlertsSuccessBody: getLanguageStringValue(
      languageStrings.resWidgetPriorityAlertsSuccessBody,
      'We will alert you if a reservation becomes available for:'
    ),
  })
)
const selectedTimeSlotVenue = state => state.searchResults.get('timeSlotVenue')

const dateMoment = state => state.search.get('dateMoment')
const partySize = state => state.search.get('partySize')
const locale = state => state.venueInfo.locale
const selectedLanguage = state => state.languages.selectedLanguage

export const selectCalculatedStrings = createSelector(
  [selectedVenueName, currentVenue, languageStrings],
  (selectedVenueName, currentVenue, languageStrings) => {
    const venuePlaceHolder = '<venue>'
    const waitlistTextVenueSpecificMarketingOptIn = (
      languageStrings.waitlistWidgetVenueMarketingOptInLabel ?? 'Receive news and offers via email for <venue>'
    ).replace(venuePlaceHolder, selectedVenueName)
    const waitlistWidgetVenueSmsMarketingOptIn = (
      languageStrings.waitlistWidgetVenueSmsMarketingOptInLabel ?? 'Receive news and offers via text for <venue>'
    ).replace(venuePlaceHolder, selectedVenueName)
    const waitlistWidgetWaitlistSeatedText = (
      languageStrings.waitlistWidgetWaitlistSeatedText ??
      'Thanks for dining at <venue>! Please let us know if we can do anything to make your experience better.'
    ).replace(venuePlaceHolder, selectedVenueName)
    return {
      textCustomPolicyHolderName: currentVenue.textCustomPolicyHolderName.replace(venuePlaceHolder, selectedVenueName),
      waitlistWidgetVenueSmsMarketingOptIn,
      waitlistTextVenueSpecificMarketingOptIn,
      waitlistWidgetWaitlistSeatedText,
    }
  }
)

const selectedTimeSlotVenueName = state => selectedTimeSlotVenue(state) && state.venueEntities[selectedTimeSlotVenue(state)].venueName

export const selectCalculatedLanguageStrings = createSelector(
  [selectLanguageStrings, selectedVenue, selectedVenueName, dateMoment, partySize, locale, selectedLanguage, selectedTimeSlotVenueName],
  (languageStrings, selectedVenue, selectedVenueName, dateMoment, partySize, locale, selectedLanguage, selectedTimeSlotVenueName) => {
    const venuePlaceHolder = '<venue>'
    const dateMomentlocale = dateMoment
      .clone()
      .locale(selectedLanguage)
      .format(shortDayFormat[locale] || shortDayFormat.default)
    return {
      textSelectATime:
        selectedVenue !== ALL_LOCATIONS
          ? languageStrings.textSelectATime.replace(venuePlaceHolder, selectedVenueName)
          : languageStrings.textSelectATimeAllLocations,
      textRestaurantClosed: languageStrings.textRestaurantClosed.replace('<date>', dateMomentlocale).replace('DATE', dateMomentlocale),
      textGuestLabel: partySize !== 1 ? languageStrings.textGuests : languageStrings.textGuest,
      textOtherDates: languageStrings.textOtherDates.replace(venuePlaceHolder, selectedVenueName),
      textNoAdditional: languageStrings.textNoAdditional.replace(venuePlaceHolder, selectedVenueName),
      textCantFind: languageStrings.textCantFind.replace(venuePlaceHolder, selectedVenueName),
      textSuccessHeader: languageStrings.textSuccessHeader.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      textSuccessBody: languageStrings.textSuccessBody.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      textVenueSpecificMarketingOptIn: languageStrings.textVenueSpecificMarketingOptIn.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      textVenueSmsMarketingOptIn: languageStrings.textVenueSmsMarketingOptIn.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      textReservationSmsOptIn: languageStrings.textReservationSmsOptIn,
      textVenueGroupMarketingOptIn: languageStrings.textVenueGroupMarketingOptIn,
      textCustomCheckoutPolicy: languageStrings.textCustomCheckoutPolicy,
      textConfirmationSentTo: languageStrings.textConfirmationSentTo.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      policyVenueGroupMarketing: languageStrings.policyVenueGroupMarketing,
      policyVenueSpecificMarketing: languageStrings.policyVenueSpecificMarketing.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      policyVenueSpecificSmsMarketing: languageStrings.policyVenueSpecificSmsMarketing.replace(venuePlaceHolder, selectedTimeSlotVenueName),
      policySmsOptIn: languageStrings.policySmsOptIn,
    }
  }
)

export const selectedOrDefaultLanguageCode = state => {
  const { venueEntities, venueInfo } = state
  const { selectedLanguage } = state.languages
  const selectedVenue = currentSelectedVenue(state)
  let defaultLanguageCode
  let enabledLanguageCodes
  if (selectedVenue in venueEntities) {
    defaultLanguageCode = venueEntities[selectedVenue].defaultLanguageCode
    enabledLanguageCodes = venueEntities[selectedVenue].enabledLanguageCodes
  } else {
    defaultLanguageCode = venueInfo.selectedLanguage
    enabledLanguageCodes = venueInfo.enabledLanguageCodes
  }
  return enabledLanguageCodes.includes(selectedLanguage) ? selectedLanguage : defaultLanguageCode
}

const languageFieldParams = state => {
  const selectedTimeSlot = state.search.get('selectedTimeSlot')
  const calendarDateMoment = state.ui.get('calendarDateMoment')
  const { locale } = state.venueInfo
  const languageCode = selectedOrDefaultLanguageCode(state)
  return {
    selectedTimeSlot,
    calendarDateMoment,
    locale,
    languageCode,
  }
}

export const selectLanguageDateFields = createSelector([languageFieldParams, dateMoment], (languageFieldParams, dateMoment) => {
  const { selectedTimeSlot, calendarDateMoment, locale, languageCode } = languageFieldParams
  const dateMomentValue = !_.isNil(dateMoment) ? dateMoment.clone().locale(languageCode) : dateMoment
  return {
    dateMoment: dateMomentValue,
    selectedTimeSlot: !_.isNil(selectedTimeSlot) ? selectedTimeSlot.clone().locale(languageCode) : selectedTimeSlot,
    datePickerLabel: !_.isNil(dateMomentValue) ? dateMomentValue.format(shortDayFormat[locale] || shortDayFormat.default) : dateMomentValue,
    calendarMonthLabel: !_.isNil(calendarDateMoment)
      ? calendarDateMoment.clone().locale(languageCode).format('MMMM YYYY')
      : calendarDateMoment.format('MMMM YYYY'),
    shouldTranslateDates: true,
  }
})
