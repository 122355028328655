import _ from 'lodash'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { ALL_TABLES } from 'mgr/blockslideout/reducers/Constants'
import Theme from 'mgr/layout/Theme'
import DropdownArrowsPicker from './DropdownArrowsPicker'
import { svrExport } from '@sevenrooms/core/utils'

const SeatingAreaTableDropDown = ({
  seatingAreasById,
  tablesById,
  tableCombosById,
  selectedIds,
  allTables,
  onChange,
  width,
  height,
  style,
  isLightTheme,
  name,
}) => {
  const seatingAreas = _.values(seatingAreasById)
  const tables = _.values(tablesById)
  const tableCombos = _.values(tableCombosById)
  const choices = _.compact([
    {
      name: 'All Tables',
      choices: [
        {
          name: 'All Tables',
          value: ALL_TABLES,
        },
      ],
    },
    {
      name: 'Seating Areas',
      choices: seatingAreas.map(sa => ({
        name: sa.name,
        value: sa.id,
      })),
    },
    {
      name: 'Tables',
      choices: tables.map(table => ({
        name: table.item_code,
        value: table.id,
      })),
    },
    {
      name: 'Table Combos',
      choices: tableCombos.map(combo => ({
        name: combo.label,
        value: combo.id,
      })),
    },
  ])
  const onChangeHandler = (values, isAddOrRemove, changedVal) => {
    if (changedVal == ALL_TABLES) {
      values = []
    }
    onChange(values)
  }
  const isMultiSelect = true
  const value = null
  const displayOverride = null
  const values = allTables ? [ALL_TABLES] : selectedIds

  return (
    <DropdownArrowsPicker
      key="SEATINGAREA_TABLE"
      name={name}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      {...{
        value,
        values,
        choices,
        displayOverride,
        onChangeHandler,
        isLightTheme,
        isMultiSelect,
        height,
      }}
    />
  )
}

SeatingAreaTableDropDown.defaultProps = {
  width: 154,
  height: 42,
  onChange: () => {},
  isLightTheme: true,
  name: 'Select Seating Areas(s) or Table(s)',
}

SeatingAreaTableDropDown.propTypes = {
  seatingAreasById: React.PropTypes.object.isRequired,
  tablesById: React.PropTypes.object.isRequired,
  tableCombosById: React.PropTypes.object.isRequired,
  selectedIds: React.PropTypes.array.isRequired,
  onChange: React.PropTypes.func.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  style: React.PropTypes.object,
  isLightTheme: React.PropTypes.bool.isRequired,
}

export default SeatingAreaTableDropDown

export const mountSeatingAreaTableDropDown = (domId, venueProps) => {
  render(
    <ThemeProvider theme={Theme}>
      <SeatingAreaTableDropDown {...venueProps} />
    </ThemeProvider>,
    document.getElementById(domId)
  )
}
svrExport('Components', 'mountSeatingAreaTableDropDown', mountSeatingAreaTableDropDown)
